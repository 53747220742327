import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useCookie } from 'react-use'
import { Customer, useUserQuery } from 'shopify/api'

type AuthContextValue = {
  authToken?: string
  userData?: Customer
  setAuthToken: (token: string, options: any, userId?: string, email?: string) => void
  removeTokens: () => void
}

export const AuthContext = React.createContext({} as AuthContextValue)

interface AuthProviderProps {
  children: React.ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [userData, setUserData] = useState<any>({} as Customer)
  const [authToken, setAuthTokenCookie, removeAuthTokenCookie] = useCookie('session-token')
  const domain = process.env.NODE_ENV === 'development' ? 'localhost' : 'wifkain.com'
  const { data } = useUserQuery({
    variables: { token: authToken! },
    skip: !authToken,
  })

  useEffect(() => {
    setUserData(data?.customer)
  }, [data?.customer])

  const removeTokens = () => {
    Cookies.remove('session-token', { domain })
    removeAuthTokenCookie()
  }

  const value = {
    setAuthToken: (token: string, options: any, userId: string, email: string) => {
      setAuthTokenCookie(token, {
        ...options,
        domain,
      })
    },
    setUserData,
    removeTokens,
    userData,
  } as AuthContextValue

  if (authToken) value.authToken = authToken as string

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
