import React from 'react'

const Loading = () => {
  return (
    <div className='flex h-[100vh] items-center justify-center'>
      <span className='h-6 w-6 block rounded-full border-4 border-t-wif-orange animate-spin'></span>
      <span className='hidden'>Loading...</span>
    </div>
  )
}
export default Loading
