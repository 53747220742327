import { ProductVariant } from 'shopify/api'
import { CartItem } from 'types'

/**
 *
 * @param {ProductVariant} variant object of ProductVariant
 * @param {string} productName Product name of the current variant
 * @param {number} quantity quantity of variant
 * @returns CartItem
 */
export const mapVariantToCartItem = (
  variant: ProductVariant,
  productName?: string,
  quantity?: number
): CartItem => {
  return {
    imageUrl: variant?.image?.url || '/icons/ic-img-placeholder.svg',
    variantName: variant?.title,
    variantId: variant?.id,
    price: variant?.priceV2.amount,
    productName: productName || '',
    quantity: quantity || 1,
    productHandle: variant?.product.handle,
  }
}
