import { merge } from 'lodash'
import React, { useContext } from 'react'
import { FinanceFormValues } from 'types'
import { AuthContext } from './AuthProvider'

type ProjectContextValue = {
  project: ProjectData
  finance: FinanceFormValues
  financeInitialized: boolean
  onNextStep: (data: ProjectData) => void
  onFinanceNextStep: (data: FinanceFormValues) => void
  onFinanceInitialize: (data: FinanceFormValues) => void
}

export type ProjectData = {
  footwear?: any
  clothes?: any
  others?: any
  accessories?: any
}

export const ProjectContext = React.createContext({} as ProjectContextValue)

interface ProjectProviderProps {
  children: React.ReactNode
}

export const ProjectProvider = ({ children }: ProjectProviderProps) => {
  const [project, setProject] = React.useState({
    others: {},
    footwear: {},
    clothes: {},
    accessories: {},
  })
  const { userData } = useContext(AuthContext)

  const [finance, setFinance] = React.useState<FinanceFormValues>({
    amount: 0,
    paymentType: '',
    tenure: '6',
    goal: '',
    type: 'Individual',
    email: userData?.email || '',
    beneficiary: {
      name: '',
      gender: '',
      birthDate: '',
      address: '',
      status: '',
      phone: '',
    },
    business: {
      name: '',
      field: '',
      numberOfEmployee: 0,
      averageMonthlyEarning: 0,
      productType: '',
      additionalInfo: '',
    },
    pic: {
      name: '',
      position: 'Owner',
      phone: '',
      email: '',
      otherPosition: '',
    },
    document: {
      selfIdCard: [],
      spouseIdCard: [],
      npwp: [],
      familyCard: [],
      nib: [],
      checkingAccount: [],
      po1: [],
      po2: [],
      po3: [],
    },
  })

  const value = {
    project,
    finance: finance as FinanceFormValues,
    onNextStep: (data: ProjectData) => {
      setProject({
        ...project,
        ...(data?.clothes && {
          clothes: {
            ...project.clothes,
            ...data.clothes,
          },
        }),
        ...(data?.footwear && {
          footwear: {
            ...project.footwear,
            ...data.footwear,
          },
        }),
        ...(data?.others && {
          others: {
            ...project.others,
            ...data.others,
          },
        }),
        ...(data?.accessories && {
          accessories: {
            ...project.accessories,
            ...data.accessories,
          },
        }),
      })
    },
    onFinanceNextStep: (data: FinanceFormValues) => {
      const merged = merge(finance, data)
      setFinance(merged)
    },
    onFinanceInitialize: (data: any) => {
      const merged = merge(finance, data)
      setFinance(merged)
    },
  } as ProjectContextValue

  return <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
}
