import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'

const storeFrontApiLink = new HttpLink({
  headers: {
    'X-Shopify-Storefront-Access-Token': process.env.NEXT_PUBLIC_SHOPIFY_GQL_TOKEN,
  },
  uri: process.env.NEXT_PUBLIC_SHOPIFY_GQL_URL,
})

const adminApiLink = new HttpLink({
  headers: {
    'X-Shopify-Access-Token': process.env.SHOPIFY_ADMIN_GQL_TOKEN,
  },
  uri: process.env.SHOPIFY_ADMIN_GQL_URL,
})

export const shopifyGraphqlClient = new ApolloClient({
  link: from([storeFrontApiLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Collection: {
        fields: {
          products: relayStylePagination(),
        },
      },
    },
  }),
})

export const shopifyAdminGraphqlClient = new ApolloClient({
  link: from([adminApiLink]),
  cache: new InMemoryCache(),
})
