import { mapVariantToCartItem } from 'helpers/cartMapper'
import { useRouter } from 'next/router'
import React from 'react'
import { useCookie } from 'react-use'
import {
  Checkout,
  CheckoutUserError,
  Customer,
  ProductVariant,
  useAddCheckoutItemMutation,
  useAttachToCheckoutMutation,
  useCreateCheckoutMutation,
  useRemoveCheckoutItemMutation,
  useUpdateCheckoutItemMutation,
  useUserQuery,
} from 'shopify/api'
import { CartItem } from 'types'
import { AuthContext } from './AuthProvider'

type CartContextValue = {
  customer?: Customer
  parsedCart: CartItem[]
  cartCount: number
  isProcessingCheckout: boolean
  isLoading: boolean
  processCheckout: () => Promise<void>
  updateCartItem: (item: CartItem, qty: number) => Promise<void>
  addToCart: (variant: ProductVariant, quantity: number, productName?: string) => Promise<void>
  removeItem: (variantId: string) => Promise<void>
  setShoppingCartCookie: (cookie: string) => void
  removeShoppingCartCookie: () => void
  refetchUser: () => void
}
export const CartContext = React.createContext({} as CartContextValue)
interface CartProviderProps {
  children: React.ReactNode
}

export const CartProvider = ({ children }: CartProviderProps) => {
  const router = useRouter()
  const { authToken } = React.useContext(AuthContext)
  const [cart, setShoppingCartCookie, removeShoppingCartCookie] = useCookie('shopping-cart')
  const [parsedCart, setParsedCart] = React.useState<CartItem[]>([])
  const [cartCount, setCartCount] = React.useState<number>(0)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isProcessingCheckout, setIsProcessingCheckout] = React.useState<boolean>(false)

  const [addCheckoutItem] = useAddCheckoutItemMutation()
  const [removeCheckoutItem] = useRemoveCheckoutItemMutation()
  const [updateCheckoutItem] = useUpdateCheckoutItemMutation()
  const [createCheckout] = useCreateCheckoutMutation()
  const [attachToCheckout] = useAttachToCheckoutMutation()

  const {
    data: userData,
    loading: fetchUserLoading,
    refetch: refetchUser,
  } = useUserQuery({
    variables: { token: authToken! },
    skip: !authToken,
  })

  React.useEffect(() => {
    const qty = parsedCart.reduce((acc, item) => acc + item.quantity, 0)
    setCartCount(qty)
  }, [parsedCart])

  React.useEffect(() => {
    cart ? setParsedCart(JSON.parse(cart!)) : setParsedCart([])
  }, [cart])

  React.useEffect(() => {
    const customer = userData?.customer as Customer

    if (customer?.lastIncompleteCheckout) {
      const { lineItems } = customer?.lastIncompleteCheckout as Checkout
      const temporaryCart = lineItems.nodes.map((item) =>
        mapVariantToCartItem(item.variant as ProductVariant, item.title, item.quantity)
      )
      setShoppingCartCookie(JSON.stringify(temporaryCart))
    }
  }, [userData])

  const value = {
    // any: because on Limited Time Sale we are using admin-api.
    addToCart: async (variant: ProductVariant | any, quantity: number, productName: string) => {
      if (authToken && userData?.customer?.lastIncompleteCheckout) {
        const checkoutId = userData.customer.lastIncompleteCheckout.id
        try {
          await addCheckoutItem({
            variables: {
              item: { variantId: variant.id, quantity },
              id: checkoutId,
            },
          })
        } catch (err) {
          console.error(`Problem adding item to checkout, checkout=${checkoutId}`)
        }
      }

      const index = parsedCart.findIndex((item) => item.variantId === variant.id)

      if (index >= 0) {
        parsedCart[index].quantity += 1
      } else {
        parsedCart.push({
          imageUrl: variant.image?.url || variant.imageSrc,
          quantity,
          variantName: variant.title,
          variantId: variant.id,
          price: variant.priceV2?.amount || variant.price,
          productName,
          productHandle: variant.product?.handle,
        })
      }

      setShoppingCartCookie(JSON.stringify(parsedCart))
    },
    updateCartItem: async (item: CartItem, quantity: number) => {
      setIsLoading(true)
      const index = parsedCart.findIndex((currentItem) => currentItem.variantId === item.variantId)

      if (userData?.customer?.lastIncompleteCheckout) {
        try {
          await updateCheckoutItem({
            variables: {
              checkoutId: userData.customer.lastIncompleteCheckout.id,
              item: {
                id: userData.customer.lastIncompleteCheckout.lineItems.nodes[index].id,
                variantId: item.variantId,
                quantity: quantity,
              },
            },
          })

          parsedCart[index].quantity = quantity
          refetchUser()
        } catch (err) {
          console.error(
            `Problem attaching checkout to customer, checkout=${userData?.customer?.lastIncompleteCheckout.id}`
          )
        }
      } else {
        parsedCart[index].quantity = quantity
      }
      setIsLoading(false)
      setShoppingCartCookie(JSON.stringify(parsedCart))
    },
    processCheckout: async () => {
      setIsProcessingCheckout(true)
      if (userData?.customer?.lastIncompleteCheckout) {
        return router.push(userData.customer.lastIncompleteCheckout.webUrl)
      }

      const lineItems = parsedCart.map((item: any) => {
        return { variantId: item.variantId, quantity: item.quantity }
      })

      const { data } = await createCheckout({
        variables: {
          input: {
            ...(authToken ? { email: userData?.customer?.email } : {}),
            lineItems,
          },
        },
      })

      const errors = data?.checkoutCreate?.checkoutUserErrors as CheckoutUserError[]

      if (errors.length < 1) {
        try {
          if (authToken) {
            const { errors: checkoutErrors } = await attachToCheckout({
              variables: {
                checkoutId: data?.checkoutCreate?.checkout?.id!,
                token: authToken!,
              },
            })

            if (!checkoutErrors || checkoutErrors?.length < 1) {
              removeShoppingCartCookie()
              return router.push(data?.checkoutCreate?.checkout?.webUrl)
            }
          }
          removeShoppingCartCookie()
          return router.push(data?.checkoutCreate?.checkout?.webUrl)
        } catch (err) {
          console.error(
            `Problem attaching checkout to customer, checkout=${data?.checkoutCreate?.checkout?.id}`
          )
        }
      }

      setIsProcessingCheckout(false)
    },
    removeItem: async (variantId: string) => {
      const index = parsedCart.findIndex((item) => item.variantId === variantId)

      if (authToken && userData?.customer?.lastIncompleteCheckout) {
        const checkoutId = userData.customer.lastIncompleteCheckout.id
        try {
          await removeCheckoutItem({
            variables: {
              checkoutId,
              itemId: userData.customer.lastIncompleteCheckout.lineItems.nodes[index].id,
            },
          })
        } catch (err) {
          console.error(
            `Problem removing item from checkout, checkout=${checkoutId}, variant=${variantId}`
          )
        }
      }

      if (index >= 0) parsedCart.splice(index, 1)
      setShoppingCartCookie(JSON.stringify(parsedCart))
    },
    parsedCart,
    setShoppingCartCookie,
    removeShoppingCartCookie,
    refetchUser,
    cartCount,
    isProcessingCheckout,
    isLoading,
  } as CartContextValue

  if (authToken) value.customer = userData?.customer as Customer

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}
