import Loading from '@/components/Loading'
import { ApolloProvider } from '@apollo/client'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import Script from 'next/script'
import 'nprogress/nprogress.css'
import { AuthProvider } from 'providers/AuthProvider'
import { CartProvider } from 'providers/CartProvider'
import { ProjectProvider } from 'providers/ProjectProvider'
import { useEffect, useState } from 'react'
import { shopifyGraphqlClient } from '../lib/shopifyGraphql'
import '../styles/main.css'

function MyApp({ Component, pageProps }: AppProps) {
  const [loading, setLoading] = useState<Boolean>(false)

  useEffect(() => {
    const start = () => {
      setLoading(true)
    }
    const end = () => {
      setLoading(false)
    }
    Router.events.on('routeChangeStart', () => start())
    Router.events.on('routeChangeComplete', () => end())
    Router.events.on('routeChangeError', () => end())
    return () => {
      Router.events.off('routeChangeStart', () => start())
      Router.events.off('routeChangeComplete', () => end())
      Router.events.off('routeChangeError', () => end())
    }
  }, [])

  return (
    <>
      <Head>
        <meta name='application-name' content='Wifkain App' />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-status-bar-style' content='default' />
        <meta name='apple-mobile-web-app-title' content='Wifkain App' />
        <meta name='description' content='Wifkain' />

        {/* used by Firefox and Opera as default description of bookmarked page */}

        <meta name='format-detection' content='telephone=no' />
        <meta name='mobile-web-app-capable' content='yes' />
        <meta name='msapplication-config' content='/icons/browserconfig.xml' />
        <meta name='msapplication-TileColor' content='#2B5797' />
        <meta name='msapplication-tap-highlight' content='no' />
        <meta name='theme-color' content='#E48E03' />

        <link rel='shortcut icon' href='/favicon.ico' />
        <link rel='manifest' href='/manifest.json' />

        {/* image when website added to apple home screen - apple-touch-icon */}
        {/* <link rel='apple-touch-icon' href='/icons/touch-icon-iphone.png' />
        <link
          rel='apple-touch-icon'
          sizes='152x152'
          href='/icons/touch-icon-ipad.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/icons/touch-icon-iphone-retina.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='167x167'
          href='/icons/touch-icon-ipad-retina.png'
        />

        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/icons/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/icons/favicon-16x16.png'
        />
        <link
          rel='mask-icon'
          href='/icons/safari-pinned-tab.svg'
          color='#5bbad5'
        />
        */}

        {/* used for twitter share link / twitter needs  */}
        {/* <meta name='twitter:card' content='summary' />
          <meta name='twitter:url' content='https://yourdomain.com' />
          <meta name='twitter:title' content='PWA App' />
          <meta name='twitter:description' content='Best PWA App in the world' />
          <meta
            name='twitter:image'
            content='https://yourdomain.com/icons/android-chrome-192x192.png'
          />
          <meta name='twitter:creator' content='@DavidWShadow' />
          */}

        {/* used for metadata when link is pasted on internet, etc */}
        {/* <meta property='og:type' content='website' />
        <meta property='og:title' content='PWA App' />
        <meta property='og:description' content='Best PWA App in the world' />
        <meta property='og:site_name' content='PWA App' />
        <meta property='og:url' content='https://yourdomain.com' />
        <meta
          property='og:image'
          content='https://yourdomain.com/icons/apple-touch-icon.png'
        /> */}
        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover'
        />
      </Head>
      <Script
        id='wifkain-gtm'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MPGZZJB');`,
        }}
      ></Script>

      <ApolloProvider client={shopifyGraphqlClient}>
        <AuthProvider>
          <CartProvider>
            <ProjectProvider>
              {loading ? <Loading /> : <Component {...pageProps} />}
            </ProjectProvider>
          </CartProvider>
        </AuthProvider>
      </ApolloProvider>
    </>
  )
}

export default appWithTranslation(MyApp)
